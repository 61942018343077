import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography, Button, Container, Grid, Card, CardContent, Box, Divider } from '@mui/material';
import { Flag, Visibility, BusinessCenter, BarChart, Engineering, Storage } from '@mui/icons-material';
import Footer from '../components/Footer';
import bannerImage from '../components/banner_aboutus.png';
import { Link } from 'react-router-dom';

function AboutUs() {
  return (
    <>
    <Helmet>
      <title>About Us - DataInsightLinx</title>
      <meta name="description" content="Contact DataInsightLinx for expert data science, engineering, and analytics solutions. Empower your business with data-driven decision-making." />
      <meta name="keywords" content="Contact, Data Science, Data Engineering, Analytics, AI Solutions, Business Intelligence" />
    </Helmet>

    {/* Banner Section */}
    <Box
      sx={{
        height: '52vh', // Full viewport height
        backgroundImage: `url(${bannerImage})`,
        backgroundSize: 'cover', // Make sure the image covers the entire section
        backgroundPosition: 'center', // Center the image
        backgroundRepeat: 'no-repeat', // Ensure the image doesn't repeat
        display: 'flex', // Flexbox to center the content
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        textAlign: 'center', // Center the text
        color: 'white', // Make the text white for better contrast
      }}
    >
      <Container>
        <Typography variant="h2" gutterBottom>
          About DataInsightLinx
        </Typography>
        <Typography variant="h6" gutterBottom>
          Data Science, Data Engineering, and Analytics tailored for your success.
        </Typography>
        <Link to="/contact" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" sx={{ px: 4, py: 2, fontSize: '1.1rem', mt: 3 }}>
            Get in Touch
          </Button>
        </Link>
      </Container>
    </Box>

    <Container>
      {/* Mission & Vision Section */}
      <Box sx={{ py: 2 }}>
        <Grid container spacing={4} sx={{ mt: 5 }}>
          <Grid item xs={12} md={6}>
            <Card sx={{ textAlign: 'center', padding: 3, height: '80%' }}>
              <CardContent>
                <Flag sx={{ fontSize: 50, color: 'primary.main' }} />
                <Typography variant="h4" gutterBottom>
                  Our Mission
                </Typography>
                <Typography paragraph>
                  At DataInsightLinx, our mission is to provide comprehensive data solutions that help businesses unlock the potential of their data, driving growth, innovation, and operational efficiency through cutting-edge technologies and methodologies.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ textAlign: 'center', padding: 3, height: '80%' }}>
              <CardContent>
                <Visibility sx={{ fontSize: 50, color: 'primary.main' }} />
                <Typography variant="h4" gutterBottom>
                  Our Vision
                </Typography>
                <Typography paragraph>
                  We envision a world where data is at the core of every decision-making process, empowering businesses to stay competitive and thrive in a data-driven future.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Purpose Section */}
      <Box sx={{ py: 8, textAlign: 'center', backgroundColor: '#f9f9f9' }}>
        <Typography variant="h4" gutterBottom align="center">
          Our Purpose
        </Typography>
        <Typography paragraph align="center">
          DataInsightLinx exists to help businesses transform complex data into meaningful insights. We aim to simplify data management, enabling organizations to make faster, smarter, and more impactful decisions.
        </Typography>
      </Box>

      {/* Services Section */}
      <Box sx={{ py: 8 }}>
        <Typography variant="h4" gutterBottom align="center">
          What We Offer
        </Typography>
        <Divider sx={{ mb: 4 }} />
        <Grid container spacing={4}>
          {/* Data Science */}
          <Grid item xs={12} md={4}>
            <Card sx={{ textAlign: 'center', padding: 3, height: '80%' }}>
              <BarChart sx={{ fontSize: 50, color: 'primary.main' }} />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Data Science & Machine Learning
                </Typography>
                <Typography paragraph>
                  We provide advanced data science solutions including predictive modeling, statistical analysis, and machine learning. Whether you're looking to automate processes, forecast trends, or personalize customer experiences, our AI and ML services are designed to give you a competitive edge.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Data Engineering */}
          <Grid item xs={12} md={4}>
            <Card sx={{ textAlign: 'center', padding: 3, height: '80%' }}>
              <Engineering sx={{ fontSize: 50, color: 'primary.main' }} />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Data Engineering
                </Typography>
                <Typography paragraph>
                We specialize in crafting efficient and scalable data engineering solutions that enable seamless data flow and management. From designing data pipelines to implementing advanced ETL processes, we ensure that your data is processed, transformed, and delivered with accuracy and speed, empowering you to extract actionable insights and drive business performance.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Data Analytics */}
          <Grid item xs={12} md={4}>
            <Card sx={{ textAlign: 'center', padding: 3, height: '80%' }}>
              <BusinessCenter sx={{ fontSize: 50, color: 'primary.main' }} />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Data Analytics & Business Intelligence
                </Typography>
                <Typography paragraph>
                  Turn your data into actionable insights with our advanced analytics services. From data visualization and reporting to business intelligence and performance metrics, we help you monitor and optimize your operations with clarity.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Data Mining */}
          <Grid item xs={12} md={4}>
            <Card sx={{ textAlign: 'center', padding: 3, height: '80%' }}>
              <Storage sx={{ fontSize: 50, color: 'primary.main' }} />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Data Mining & Pattern Discovery
                </Typography>
                <Typography paragraph>
                  We extract valuable insights from large datasets using data mining techniques such as clustering, classification, and anomaly detection. Uncover hidden patterns and trends that can drive strategic decisions and enhance operational performance.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Big Data Solutions */}
          <Grid item xs={12} md={4}>
            <Card sx={{ textAlign: 'center', padding: 3, height: '80%' }}>
              <Storage sx={{ fontSize: 50, color: 'primary.main' }} />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Big Data & Cloud Solutions
                </Typography>
                <Typography paragraph>
                  Leverage the power of big data with our scalable cloud-based solutions. We handle large-scale data processing and storage, allowing you to make real-time decisions and unlock new insights from vast datasets.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Custom AI Solutions */}
          <Grid item xs={12} md={4}>
            <Card sx={{ textAlign: 'center', padding: 3, height: '80%' }}>
              <BarChart sx={{ fontSize: 50, color: 'primary.main' }} />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Custom AI & Automation Solutions
                </Typography>
                <Typography paragraph>
                  From chatbots to recommendation engines, we create custom AI solutions tailored to your business needs. Automate workflows, improve efficiency, and deliver personalized customer experiences through intelligent systems.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>

    {/* Footer Section */}
    <Footer />
    </>
  );
}

export default AboutUs;