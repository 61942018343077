import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { TextField, Button, Container, Alert, Box, Typography, Grid, Card, CardContent } from '@mui/material';
import axios from 'axios';
import { Email, LocationOn } from '@mui/icons-material';
import Footer from '../components/Footer';
import bannerImage from '../components/banner_contact.png';
// import { Link } from 'react-router-dom';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    subject: '',
    email: '',
    phone: '',
    message: '',
  });
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // Clear errors when user modifies the input
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Set API URL based on environment
    const apiUrl = process.env.NODE_ENV === 'production' 
        ? '/api/contact/'  // Relative URL in production
        : 'http://localhost:8000/api/contact/';

    axios
      .post(apiUrl, formData)
      .then((res) => {
        setSuccess(true);
        setFormData({ name: '', subject: '', email: '', phone: '', message: '' });
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        } else {
          console.error(err);
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - DataInsightLinx</title>
        <meta name="description" content="Contact DataInsightLinx for expert data science, engineering, and analytics solutions. Empower your business with data-driven decision-making." />
        <meta name="keywords" content="Contact, Data Science, Data Engineering, Analytics, AI Solutions, Business Intelligence" />
      </Helmet>

      {/* Banner Section */}
      <Box
        sx={{
          height: '52vh',
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          color: 'white',
        }}
      >
        <Container>
          <Typography variant="h2" gutterBottom>
            Get in Touch with DataInsightLinx
          </Typography>
          <Typography variant="h6" gutterBottom>
            We're here to help you with all your data science, engineering, and analytics needs.
            Contact us today to learn how we can transform your business with cutting-edge data solutions.
          </Typography>
          {/* <Button
            variant="contained"
            color="primary"
            sx={{ px: 4, py: 2, fontSize: '1.1rem', mt: 3 }}
            component={Link}
            to="/contact"
          >
            Get in Touch
          </Button> */}
        </Container>
      </Box>
      <Container>
        <Grid container spacing={4} justifyContent="center">
          {/* Contact Form */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                {success && <Alert severity="success">Message sent successfully!</Alert>}
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Name"
                    name="name"
                    fullWidth
                    margin="normal"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                  <TextField
                    label="Subject"
                    name="subject"
                    fullWidth
                    margin="normal"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    error={!!errors.subject}
                    helperText={errors.subject}
                  />
                  <TextField
                    label="Email"
                    name="email"
                    type="email"
                    fullWidth
                    margin="normal"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                  <TextField
                    label="Phone"
                    name="phone"
                    fullWidth
                    margin="normal"
                    value={formData.phone}
                    onChange={handleChange}
                    // error={!!errors.phone}
                    // helperText={errors.phone}
                  />
                  <TextField
                    label="Message"
                    name="message"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                    required
                    error={!!errors.message}
                    helperText={errors.message}
                  />
                  <Button variant="contained" color="primary" type="submit" fullWidth sx={{ mt: 2 }}>
                    Send
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom align="center">
                  Contact Information
                </Typography>

                {/* <Box display="flex" alignItems="center" mb={2}>
                    <Phone sx={{ mr: 1 }} />
                    <Typography variant="body1">+44 123 456 7890</Typography>
                </Box> */}

                <Box display="flex" alignItems="center" mb={2}>
                  <Email sx={{ mr: 1 }} />
                  <Typography variant="body1">info@datainsightlinx.com</Typography>
                </Box>

                <Box display="flex" alignItems="center">
                  <LocationOn sx={{ mr: 1 }} />
                  <Typography variant="body1">
                    Oxford, United Kingdom
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* Footer Section */}
      <Footer />
    </>
  );
}

export default Contact;