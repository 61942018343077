import React from 'react';
import { Typography, Box, Grid, Link, IconButton } from '@mui/material';
import { Facebook, Twitter, LinkedIn } from '@mui/icons-material';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#333',
        color: 'white',
        py: 3,
        px: 2,
        mt: 8, // Margin top to separate it from content
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        {/* Company Info Section */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6" gutterBottom>
            DataInsightLinx
          </Typography>
          <Typography paragraph>
            Delivering cutting-edge data solutions for business growth and innovation.
          </Typography>
        </Grid>

        {/* Quick Links */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6" gutterBottom>
            Quick Links
          </Typography>
          <Box>
            <Link href="/" color="inherit" underline="hover" component="a">
              Home
            </Link>
          </Box>
          <Box>
            <Link href="/about-us" color="inherit" underline="hover" component="a">
              About Us
            </Link>
          </Box>
          <Box>
            <Link href="/contact" color="inherit" underline="hover" component="a">
              Contact
            </Link>
          </Box>
          <Box>
            <Link href="/privacy-policy" color="inherit" underline="hover" component="a">
              Privacy Policy
            </Link>
          </Box>
        </Grid>

        {/* Social Media Links */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6" gutterBottom>
            Follow Us
          </Typography>
          <IconButton
            color="inherit"
            aria-label="Facebook"
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="Twitter"
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="LinkedIn"
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedIn />
          </IconButton>
        </Grid>
      </Grid>

      {/* Copyright Section */}
      <Box textAlign="center" pt={5}>
        <Typography variant="body2" color="inherit">
          &copy; {new Date().getFullYear()} DataInsightLinx. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;