import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, ListItemButton, Box } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import logo from './logo.png';

function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        DataInsightLinx
      </Typography>
      <List>
      <ListItem disablePadding>
        <ListItemButton component={Link} to="/">
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/about-us">
            <ListItemText primary="About Us" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/contact">
            <ListItemText primary="Contact" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar component="nav" position="static" sx={{ background: '#333', boxShadow: 'none', color: '#white' }}>
        <Toolbar
            sx={{
                display: 'flex',
                justifyContent: 'center', // Horizontally center the content
                alignItems: 'center', // Vertically align items in the center
                gap: 2 // Space between items
            }}>
            {/* Logo */}
            <IconButton 
                edge="start" 
                color="inherit" 
                aria-label="menu" 
                sx={{ mr: 2 }}
                component={Link}
                to="/" // Link to Home Page
            >
                <img src={logo} alt="DataInsightLinx" style={{ height: '50px', backgroundColor: '#f0f0f0'}} />
            </IconButton>
            {/* Menu Icon for Mobile View */}
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
            >
                <MenuIcon />
            </IconButton>
            {/* Company Logo or Name */}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                DataInsightLinx
            </Typography>
            {/* Navigation Links for Desktop View */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Button color="inherit" component={Link} to="/">
                  Home
                </Button>
                <Button color="inherit" component={Link} to="/about-us">
                  About Us
                </Button>
                <Button color="inherit" component={Link} to="/contact">
                  Contact
                </Button>
            </Box>
        </Toolbar>
      </AppBar>
      {/* Side Drawer for Mobile View */}
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}

export default Navbar;