import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography, Button, Container, Box, Grid, Card, CardContent, Chip } from '@mui/material';
import bannerImage from '../components/banner_home.png';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

// Import Material-UI icons
import ScienceIcon from '@mui/icons-material/Science';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AnalyticsIcon from '@mui/icons-material/Analytics';
// import ReactIcon from '@mui/icons-material/Code'; // Use as a placeholder for React.js

import BusinessImage from '../components/Finance.png';
import HealthAndSafetyImage from '../components/healthcare.png';
import SchoolImage from '../components/Education.png';
import ManufacturingImage from '../components/manufacturing.png';

// Import images for Technologies section
import PythonImage from '../components/Python-logo.png';
import MSAzureImage from '../components/microsoft-azure-logo.png';
import SQLImage from '../components/microsoft-sql-logo.png';
import TableauImage from '../components/tableau-logo.png';
import PowerBIImage from '../components/power-bi-logo.png';
import DjangoImage from '../components/django-logo.png';
import ReactImage from '../components/react-js-logo.png';

function Home() {
  return (
    <>
    <Helmet>
      <title>DataInsightLinx</title> 
      <meta name="description" content="DataInsightLinx specializes in data science, data engineering, and analytics tailored for your success." />
      <meta name="keywords" content="data science, data engineering, analytics, business intelligence, machine learning, AI, data warehousing, ETL, big data, cloud solutions, data architecture, data visualization, performance metrics, Python, MS Azure, SQL, Tableau, Power BI, Django, React.js" />
    </Helmet>


    {/* Banner Section */}
    <Box
      sx={{
        height: '52vh', // Full viewport height
        backgroundImage: `url(${bannerImage})`,
        backgroundSize: 'cover', // Make sure the image covers the entire section
        backgroundPosition: 'center', // Center the image
        backgroundRepeat: 'no-repeat', // Ensure the image doesn't repeat
        display: 'flex', // Flexbox to center the content
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        textAlign: 'center', // Center the text
        color: 'white', // Make the text white for better contrast
      }}
    >
      <Container>
        <Typography variant="h2" gutterBottom>
          Welcome to DataInsightLinx
        </Typography>
        <Typography variant="h6" gutterBottom>
          Data Science, Data Engineering, and Analytics tailored for your success.
        </Typography>
        <Link to="/contact" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" sx={{ px: 4, py: 2, fontSize: '1.1rem', mt: 3 }}>
            Get in Touch
          </Button>
        </Link>
      </Container>
    </Box>

    {/* Services Section */}
    <Box sx={{ backgroundColor: '#f9f9f9', py: 8, mb: 5 }}>
      <Container sx={{ mt: 5 }}>
          <Typography variant="h4" gutterBottom align="center">
            Our Services
          </Typography>

          <Grid container spacing={4}>

            {/* Data Science Card */}
            <Grid item xs={12} md={4}>
              <Card sx={{ textAlign: 'center', padding: 3,height: '95%' }}>
                <ScienceIcon sx={{ fontSize: 50, color: 'primary.main' }} />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Data Science Consulting
                  </Typography>
                  <Typography paragraph>
                  We specialize in predictive modelling, advanced machine learning, statistical analysis, and AI integration services for businesses. Unlock the full potential of your data with our expert Data Science consultancy, driving actionable insights and data-driven growth.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Data Engineering Card */}
            <Grid item xs={12} md={4}>
              <Card sx={{ textAlign: 'center', padding: 3, height: '95%' }}>
                <EngineeringIcon sx={{ fontSize: 50, color: 'primary.main' }} />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Data Engineering Expertise
                  </Typography>
                  <Typography paragraph>
                    From data warehousing and ETL processes to big data processing and cloud-based solutions, we ensure your data architecture is optimized and scalable.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Data Analytics Card */}
            <Grid item xs={12} md={4}>
              <Card sx={{ textAlign: 'center', padding: 3, height: '95%' }}>
                <AnalyticsIcon sx={{ fontSize: 50, color: 'primary.main' }} />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Advanced Data Analytics
                  </Typography>
                  <Typography paragraph>
                    Transform raw data into insights with our advanced analytics services, including business intelligence, data visualization, and performance metrics analysis.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

        {/* Industries Section */}
        <Box sx={{ py: 2, mb: 5 }}>
          <Container>
            <Typography variant="h4" gutterBottom align="center">
            Industries We Empower
            </Typography>
            <Grid container spacing={4} justifyContent="center">
            {/* Finance */}
            <Grid item xs={12} sm={6} md={3} textAlign="center">
              <img src={BusinessImage} alt="Finance" style={{ width: 200, height: 200, marginBottom: 5 }} />
                {/* <BusinessImage sx={{ fontSize: 50, color: 'primary.main', mb: 2 }} /> */}
                <Typography variant="h6">
                Finance
                </Typography>
            </Grid>

            {/* Healthcare */}
            <Grid item xs={12} sm={6} md={3} textAlign="center">
                <img src={HealthAndSafetyImage} alt="Healthcare" style={{ width: 200, height: 200, marginBottom: 5 }} />
                {/* <HealthAndSafetyImage sx={{ fontSize: 50, color: 'primary.main', mb: 2 }} /> */}
                <Typography variant="h6">
                Healthcare
                </Typography>
            </Grid>

            {/* Manufacturing */}
            <Grid item xs={12} sm={6} md={3} textAlign="center">
                <img src={ManufacturingImage} alt="Manufacturing" style={{ width: 200, height: 200, marginBottom: 10 }} />
                {/* <ManufacturingImage sx={{ fontSize: 50, color: 'primary.main', mb: 2 }} /> */}
                <Typography variant="h6">
                Manufacturing
                </Typography>
            </Grid>

            {/* Education */}
            <Grid item xs={12} sm={6} md={3} textAlign="center">
                <img src={SchoolImage} alt="Education" style={{ width: 200, height: 200, marginBottom: 5 }} />
                {/* <SchoolImage sx={{ fontSize: 50, color: 'primary.main', mb: 2 }} /> */}
                <Typography variant="h6">
                Education
                </Typography>
            </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Technologies Section */}
        <Box sx={{ backgroundColor: '#f9f9f9', py: 8, mb: 6 }}>
          <Container>
            <Typography variant="h4" gutterBottom align="center">
              Technologies We Specialize In
            </Typography>
            <Typography paragraph align="center">
              We harness the power of these technologies to fuel innovation:
            </Typography>
            <Grid container spacing={1} justifyContent="center" alignItems="center">
              {/* Python */}
              <Grid item xs={4} sm={3} md={1.5} textAlign="center">
                <img
                  src={PythonImage}
                  alt="Python"
                  style={{
                    width: '50px',
                    height: 'auto',
                    marginBottom: '10px',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
                <Typography variant="subtitle1">Python</Typography>
              </Grid>
              {/* MS Azure */}
              <Grid item xs={4} sm={3} md={1.5} textAlign="center">
                <img
                  src={MSAzureImage}
                  alt="MS Azure"
                  style={{
                    width: '50px',
                    height: 'auto',
                    marginBottom: '10px',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
                <Typography variant="subtitle1">MS Azure</Typography>
              </Grid>
              {/* SQL */}
              <Grid item xs={4} sm={3} md={1.5} textAlign="center">
                <img
                  src={SQLImage}
                  alt="SQL"
                  style={{
                    width: '50px',
                    height: 'auto',
                    marginBottom: '10px',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
                <Typography variant="subtitle1">SQL</Typography>
              </Grid>
              {/* Tableau */}
              <Grid item xs={4} sm={3} md={1.5} textAlign="center">
                <img
                  src={TableauImage}
                  alt="Tableau"
                  style={{
                    width: '50px',
                    height: 'auto',
                    marginBottom: '10px',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
                <Typography variant="subtitle1">Tableau</Typography>
              </Grid>
              {/* Power BI */}
              <Grid item xs={4} sm={3} md={1.5} textAlign="center">
                <img
                  src={PowerBIImage}
                  alt="Power BI"
                  style={{
                    width: '50px',
                    height: 'auto',
                    marginBottom: '10px',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
                <Typography variant="subtitle1">Power BI</Typography>
              </Grid>
              {/* Django */}
              <Grid item xs={4} sm={3} md={1.5} textAlign="center">
                <img
                  src={DjangoImage}
                  alt="Django"
                  style={{
                    width: '50px',
                    height: 'auto',
                    marginBottom: '10px',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
                <Typography variant="subtitle1">Django</Typography>
              </Grid>
              {/* React.js */}
              <Grid item xs={4} sm={3} md={1.5} textAlign="center">
                <img
                  src={ReactImage}
                  alt="React.js"
                  style={{
                    width: '50px',
                    height: 'auto',
                    marginBottom: '10px',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
                <Typography variant="subtitle1">React.js</Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      

      {/* Footer Section */}
      <Footer />
    </>
  );
}

export default Home;